// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App{
  text-align: center;
  text-shadow: 1px 2px 3px rgba(123, 126, 138, 0.8);
  width: 80%;
  margin:auto;
  overflow:hidden;
  border-left: #27272c 3px solid;
  border-right: #27272c 3px solid;

}

.header1{
  background-image: radial-gradient(circle, #3868b5, #0b2d63);
  font-size: 30px;
  color: aliceblue;
  text-shadow: 0px 4px 10px rgba(16, 15, 16, 0.8);
  padding-top: 30px;
  padding-bottom: 30px;
  border-top: #27272c 3px solid;
  border-bottom: #27272c 3px solid;
}

.footer1{
  background-image: radial-gradient(circle, #3868b5, #0b2d63);
  font-size: 30px;
  color: aliceblue;
  text-shadow: 0px 4px 10px rgba(16, 15, 16, 0.8);
  padding-top: 30px;
  padding-bottom: 30px;
  border-top: #27272c 3px solid;
  border-bottom: #27272c 3px solid;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,iDAAiD;EACjD,UAAU;EACV,WAAW;EACX,eAAe;EACf,8BAA8B;EAC9B,+BAA+B;;AAEjC;;AAEA;EACE,2DAA2D;EAC3D,eAAe;EACf,gBAAgB;EAChB,+CAA+C;EAC/C,iBAAiB;EACjB,oBAAoB;EACpB,6BAA6B;EAC7B,gCAAgC;AAClC;;AAEA;EACE,2DAA2D;EAC3D,eAAe;EACf,gBAAgB;EAChB,+CAA+C;EAC/C,iBAAiB;EACjB,oBAAoB;EACpB,6BAA6B;EAC7B,gCAAgC;AAClC","sourcesContent":[".App{\n  text-align: center;\n  text-shadow: 1px 2px 3px rgba(123, 126, 138, 0.8);\n  width: 80%;\n  margin:auto;\n  overflow:hidden;\n  border-left: #27272c 3px solid;\n  border-right: #27272c 3px solid;\n\n}\n\n.header1{\n  background-image: radial-gradient(circle, #3868b5, #0b2d63);\n  font-size: 30px;\n  color: aliceblue;\n  text-shadow: 0px 4px 10px rgba(16, 15, 16, 0.8);\n  padding-top: 30px;\n  padding-bottom: 30px;\n  border-top: #27272c 3px solid;\n  border-bottom: #27272c 3px solid;\n}\n\n.footer1{\n  background-image: radial-gradient(circle, #3868b5, #0b2d63);\n  font-size: 30px;\n  color: aliceblue;\n  text-shadow: 0px 4px 10px rgba(16, 15, 16, 0.8);\n  padding-top: 30px;\n  padding-bottom: 30px;\n  border-top: #27272c 3px solid;\n  border-bottom: #27272c 3px solid;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
